<template>
  <b-overlay :show="loading">
    <b-alert
      variant="danger"
      :show="userData === undefined && !loading"
    >
      <h4 class="alert-heading">
        Error fetching User details
      </h4>
      <div class="alert-body">
        <b-link
          class="alert-link"
          :to="{ name: 'admin-users'}"
        >
          Users
        </b-link>
      </div>
    </b-alert>

    <template v-if="userData">
      <validation-observer ref="newUserForm" #default="{invalid}">
        <b-form ref="form" @submit.prevent="onUpdateUser">
          <b-row>
            <b-col
              cols="12"
              xl="8"
              md="8"
              >
              <div>
                <b-card>
                  <b-row class="">
                    <b-col
                      cols="11"
                    >
                      <div class="d-flex align-items-center">
                        <feather-icon
                          icon="UserIcon"
                          size="19"
                        />
                        <h4 class="mb-0 ml-50">
                          Personal Information
                        </h4>
                      </div>
                    </b-col>

                    <b-col
                      cols="1"
                    >
                      <div v-if="canDeleteMFA && userData.mfa_conf"
                          class="justify-content-end"
                      >
                        <b-dropdown variant="link" class="p-0" no-caret :right="$store.state.appConfig.isRTL">
                          <template #button-content class="p-0">
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-top text-body" />
                          </template>
                          <b-dropdown-item @click="onResetUserMFA">
                            <feather-icon class="text-danger" icon="EditIcon" />
                            <span class="align-middle text-danger ml-50">Reset MFA</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </b-col>
                  </b-row>

                  <!-- Media -->
                  <b-media class="mt-3 mb-3">
                    <template #aside>
                      <b-avatar
                        ref="previewEl"
                        :src="avatarPath"
                        :text="avatarText(`${userData.first_name} ${userData.last_name}`)"
                        :variant="`light-${resolveStatusVariant(getLoanStatusTextForClient(getValueFromSource(userData, 'status')))}`"
                        size="6rem"
                      />
                    </template>

                    <h4 class="mb-1">
                      Avatar
                    </h4>

                    <div v-if="can('users:CanUpdate')" class="d-flex flex-wrap">
                      <b-button
                        variant="primary"
                        :disabled="!hasBasicUpdatePermission"
                        @click="$refs.avatarElement.$el.childNodes[0].click()"
                      >
                        <b-form-file
                          v-show="false"
                          ref="avatarElement"
                          accept="image/*"
                          placeholder=""
                          no-drop
                          @input="onImageRenderer"
                        />
                        <span class="d-none d-sm-inline">Change Avatar</span>
                        <feather-icon
                          icon="EditIcon"
                          class="d-inline d-sm-none"
                        />
                      </b-button>

                      <b-button
                        v-if="getValueFromSource(userData, 'avatar.path')"
                        :disabled="!hasBasicUpdatePermission"
                        variant="outline-secondary"
                        class="ml-1"
                        @click="onRemoveAvatar"
                      >
                        <span class="d-none d-sm-inline">Remove</span>
                        <feather-icon
                          icon="TrashIcon"
                          class="d-inline d-sm-none"
                        />
                      </b-button>
                    </div>
                  </b-media>

                  <b-row class="">
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group
                        label="First Name"
                        label-for="first_name"
                      >
                        <b-form-input
                          id="first_name"
                          v-model="userData.first_name"
                          :disabled="!hasBasicUpdatePermission"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group
                        label="Last Name"
                        label-for="last_name"
                      >
                        <b-form-input
                          id="last_name"
                          v-model="userData.last_name"
                          :disabled="!hasBasicUpdatePermission"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row class="">
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group
                        label="Email"
                        label-for="email"
                      >
                        <b-form-input
                          id="email"
                          v-model="userData.email"
                          type="email"
                          :disabled="!hasBasicUpdatePermission"
                        />
                      </b-form-group>
                    </b-col>
                    
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group
                        label="Phone"
                        label-for="phone"
                      >
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            id="phone"
                            v-model="userData.phone"
                            :disabled="!hasBasicUpdatePermission"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row v-if="userData.user_type ==='client'" class="">
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-form-group
                        label="Birth Date"
                        label-for="date_of_birth"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Date Of Birth"
                          rules="required|validate_date"
                        >
                         <cleave
                            v-model="userData.date_of_birth"
                            class="form-control"
                            placeholder="YYYY-MM-DD"
                            :raw="false"
                            :options="cleaveDateOptions"
                            :disabled="!hasBasicUpdatePermission"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                      <b-form-group
                        label="Fellowship Region"
                        label-for="region"
                      >
                        <b-form-input
                          id="region"
                          v-model="userData.church_region"
                          disabled
                        />
                      </b-form-group>

                      <b-form-group
                        label="Fellowship Area"
                        label-for="area"
                      >
                        <b-form-input
                          id="area"
                          v-model="userData.church_area"
                          disabled
                        />
                      </b-form-group>

                      <b-form-group
                        label="Fellowship District"
                        label-for="district"
                      >
                        <b-form-input
                          id="district"
                          v-model="userData.church_district"
                          disabled
                        />
                      </b-form-group>

                      <b-form-group
                        label="Fellowship Assembly"
                        label-for="assembly"
                      >
                        <b-form-input
                          id="assembly"
                          v-model="userData.church_assembly"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>

                 <b-button
                  class="mb-5"
                  style="width: 200px"
                  variant="primary"
                  block
                  type="submit"
                  :disabled="invalid || disableForm || loading"
                >
                  Update {{ capitalizeFirstWord(getValueFromSource(userData, 'user_type')) }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BAlert, BLink, BRow, BCol,
  BCard, BCardBody, BButton, BFormGroup, 
  BForm, BFormInput, BMedia, BAvatar,
  BFormFile, BFormDatepicker, BBadge,
  BInputGroup, BInputGroupPrepend, BDropdown,
  BDropdownItem, BFormSelect
} from "bootstrap-vue"
import Cleave from 'vue-cleave-component'

import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

import { get } from "lodash";
import { required, email } from '@validations'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';


export default {
  components: {
    Cleave,
    vSelect,
    flatPickr,
    BOverlay,
    BAlert,
    BLink,
    BRow,
    BCol,
    BCard, 
    BCardBody, 
    BButton,
    BFormGroup, 
    BForm, 
    BFormInput,
    BMedia, 
    BAvatar,
    BFormFile,
    BFormDatepicker,
    BBadge,
    BInputGroup,
    BDropdown,
    BFormSelect,
    BDropdownItem,
    BInputGroupPrepend,
    
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {},
  data(){
    return {
      loading: false,

      userData: null,
      permissionOptions: [],

      avatarPath: "",
      updatedImage: null,
      cleaveDateOptions: {
        date: true,
        delimiter: '/',
        datePattern: ['Y', 'm', 'd'],
        blocks: [4, 2, 2],
        delimiters: ['-', '-', '-']
      },
      required,
      email
    }
  },
  computed: {
    hasBasicUpdatePermission(){
      return this.can('users:CanUpdate');
    },
    disableForm(){
      return !this.hasBasicUpdatePermission
    },
    canDeleteMFA() {
      return this.can("mfa_configuration:CanDelete");
    },
  },
  created(){
    this.fetchUserDetails();
  },
  methods: {
    async fetchUserDetails(){
      try {
        this.loading = true;
        const { id } = this.$route.params;
        
        const response = await this.useJwt().adminService.fetchUser(id);
        const userData = get(response, 'data.data');
        
        this.userData = userData
        this.avatarPath = this.getValueFromSource(this.userData, 'avatar.path', '');
      } catch (error){
        this.userData = undefined;

        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async onUpdateUser(){
      try {
        this.loading = true;

        const formData = new FormData();
        if (this.hasBasicUpdatePermission){
          formData.append('first_name', this.getValueFromSource(this.userData, 'first_name', ''));
          formData.append('last_name', this.getValueFromSource(this.userData, 'last_name', ''));
          formData.append('email', this.getValueFromSource(this.userData, 'email', ''));
          formData.append('phone', this.getValueFromSource(this.userData, 'phone', ''));
          formData.append('date_of_birth', this.getValueFromSource(this.userData, 'date_of_birth', ''));
        }


        if (this.updatedImage){
          formData.append("avatar", this.updatedImage);
        }

        if (!this.hasBasicUpdatePermission){
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: "An unexpected error occurred. You might not have permission to update record."
            },
          });
          return false;
        }

        const user_id = this.userData._id;
        await this.useJwt().adminService.updateUser(user_id, formData);
        await this.fetchUserDetails();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'EditIcon',
            variant: 'success',
            text: `User updated successfully.`
          },
        });
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async onRemoveAvatar(){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Avatar Removal?',
          text: "Are you sure you want to remove this avatar?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, remove it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (!result.value) {
          return;
        }

        const user_id = this.userData._id;
        if (!user_id){
          return;
        }

        await this.useJwt().adminService.deleteUserAvatar(user_id);
        this.avatarPath = "";
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    onImageRenderer(file) {
     const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.avatarPath = reader.result
          this.updatedImage = file;
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
    async onResetUserMFA() {
      try {
        this.loading = true;
        const result = await this.$swal({
          title: 'Reset User MFA Config',
          text: "Are you sure you want to reset user mfa configuration?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });
        if (!result.value || !this.userData._id) {
          return;
        }
        await this.useJwt().adminService.resetUserMFA(this.userData._id);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: "MFA on Admin User account has been reset successfully."
          }
        });
        await this.$router.push({
          name: "admin-users"
        })
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
  }
}
</script>
